'use strict';

document.addEventListener("DOMContentLoaded", function () {
    var methodOverviewInstances = [];
    $('[data-method-overview-page]').each(function () {
        methodOverviewInstances.push(new MethodOverviewPage(this));
    })
});


function MethodOverviewPage(elem) {
    this.elem = elem;
    var self = this;
    var isLoading = false;
    
    var config = JSON.parse(elem.getAttribute('data-method-overview-page'));

    //Filter
    var categoryFilterBtn = elem.querySelector('[data-category-filter]');
    var categoryFilterBtnMobile = elem.querySelector('[data-category-filter-mobile]');

    var ageFilterBtn = elem.querySelector('[data-age-filter]');
    var ageFilterBtnMobile = elem.querySelector('[data-age-filter-mobile]');

    var categoryDropdown = elem.querySelector('[data-category-dropdown]');
    var categoryDropdownMobile = elem.querySelector('[data-category-dropdown-mobile]');

    var ageDropdown = elem.querySelector('[data-age-dropdown]');
    var ageDropdownMobile = elem.querySelector('[data-age-dropdown-mobile]');


    let enabledFilterAgeCheckbox = []
    let enabledFilterCategoryCheckbox = []

    var filterCategoryCheckbox = elem.querySelectorAll('input[type="checkbox"][name="filterCategory"]');
    var filterAgeCheckbox = elem.querySelectorAll('input[type="checkbox"][name="filterAge"]');

    var resetFilterBtn = elem.querySelector('[data-reset-filter]');
    var resetFilterBtnMobile = elem.querySelector('[data-reset-filter-mobile]');

    //Filter Mobile
    var filterMobileBtn = elem.querySelector('[data-filter-mobile]');
    var filterMobileCloseBtn = elem.querySelector('[data-filter-mobile-close]');
    var showResultMobileBtn = elem.querySelector('[data-method-overview-page__show-result]');


    //Load More
    var loadMoreBtn = elem.querySelector('[data-method-overview-page__load-more]');
    var methodOverviewLoadMore = elem.querySelector('.methodOverviewLoadMore');
    var methodOverviewList = elem.querySelector('.methodOverviewList');

    var resultNumber = elem.querySelector('.method-overview-page__filter__results span');

    var filter = {
        page: 1,
        age: null,
        categories: null
    }

    if (loadMoreBtn) {
        loadMoreBtn.addEventListener('click', loadMore);
    }
    function updateFilter() {
        filter.age = enabledFilterAgeCheckbox.map(x => x.value).join(',');
        filter.categories = enabledFilterCategoryCheckbox.map(x => x.value).join(',');

        //if (isLoading) {
        //    return;
        //}

        filter.page = 1;

        console.warn('perform request to backend to fetch new page.')
        window.methodsService.fetchMethodsByFilter(filter, "small-12 medium-6 xlarge-4", config.culture, function (result) {
            config.totalPages = result.TotalPages;
            config.totalResults = result.TotalResults;

            resultNumber.innerHTML = result.TotalResults;
            methodOverviewList.innerHTML = result.Content;

            isLoading = true;

            window.initializeFavoritizeButtons();

            // if we have loaded the last page, hide the button.
            if (loadMoreBtn) {
                if (filter.page >= config.totalPages) {
                    loadMoreBtn.classList.add('hide');
                    loadMoreBtn.classList.remove('show');
                }
                else {
                    loadMoreBtn.classList.add('show');
                    loadMoreBtn.classList.remove('hide');

                }
            }
        });
    }

    function loadMore() {
        //if (isLoading) {
        //    return;
        //}

        filter.page++;

        console.warn('perform request to backend to fetch new page.')

        window.methodsService.fetchMethodsByFilter(filter, "small-12 medium-6 xlarge-4", config.culture, function (result) {
            methodOverviewList.insertAdjacentHTML('beforeend', result.Content);
            isLoading = true;

            window.initializeFavoritizeButtons();

            // if we have loaded the last page, hide the button.
            if (filter.page >= config.totalPages) {
                loadMoreBtn.classList.add('hide');

                if (loadMoreBtn) {
                    loadMoreBtn.classList.add('hide');
                    loadMoreBtn.classList.remove('show');
                }
            }
        });
    }

    // Count and update number of checked category filter
    filterCategoryCheckbox.forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {

            enabledFilterCategoryCheckbox = Array.from(filterCategoryCheckbox).filter(i => i.checked)
            updateCheckedNumber(enabledFilterCategoryCheckbox);
        })
    });

    // Count and update number of checked age filter
    filterAgeCheckbox.forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {

            enabledFilterAgeCheckbox = Array.from(filterAgeCheckbox).filter(i => i.checked)
            updateCheckedNumber(enabledFilterAgeCheckbox);
        })
    });

    function updateCheckedNumber(enabledFilterCheckbox) {

        var numberOfCheckedFilter = enabledFilterCheckbox.length;
        var openFilter = elem.querySelector('.filter-item.display');

        if (openFilter) {
            var checkedFilter = elem.querySelector('.display span');
            checkedFilter.innerHTML = " (" + numberOfCheckedFilter + ") ";

            if (numberOfCheckedFilter == 0) {
                checkedFilter.innerHTML = "";
            }
        }
        updateFilter();
    }

    resetFilterBtn.addEventListener('click', resetFilter);

    function resetFilter() {

        for (var checkbox of filterCategoryCheckbox) {
            checkbox.checked = false;

            var checkedFilter = elem.querySelector('[data-category-filter] .category-filter-item__number');
            checkedFilter.innerHTML = ""; 
        }

        for (var checkbox of filterAgeCheckbox) {
            checkbox.checked = false;

            var checkedFilter = elem.querySelector('[data-age-filter] .age-filter-item__number');
            checkedFilter.innerHTML = "";
        }

        categoryDropdown.classList.remove("display");
        categoryFilterBtn.classList.remove("display");
        ageFilterBtn.classList.remove("display");
        ageDropdown.classList.remove("display");

        enabledFilterAgeCheckbox = []
        enabledFilterCategoryCheckbox = []

        updateFilter();
    }

    resetFilterBtnMobile.addEventListener('click', resetFilterMobile);

    function resetFilterMobile() {
        for (var checkbox of filterCategoryCheckbox) {
            checkbox.checked = false;

            var checkedFilterMobile = elem.querySelector('[data-category-filter-mobile] .category-filter-item__number');
            checkedFilterMobile.innerHTML = "";
        }

        for (var checkbox of filterAgeCheckbox) {
            checkbox.checked = false;

            var checkedFilterMobile = elem.querySelector('[data-age-filter-mobile] .age-filter-item__number');
            checkedFilterMobile.innerHTML = "";

        }

        categoryDropdownMobile.classList.remove("display");
        categoryFilterBtn.classList.remove("display");
        ageFilterBtn.classList.remove("display");
        ageDropdownMobile.classList.remove("display");

        enabledFilterAgeCheckbox = []
        enabledFilterCategoryCheckbox = []

        updateFilter();
    }

    ageFilterBtn.addEventListener('click', displayAges);
    ageFilterBtnMobile.addEventListener('click', displayAges);

    function displayAges() {
        //remove display from category filter and dropdown
        categoryFilterBtn.classList.remove("display");
        categoryDropdown.classList.remove("display");
        ageDropdown.classList.toggle("display");
        ageDropdownMobile.classList.toggle("display");

        this.classList.toggle("display");
    }

    categoryFilterBtn.addEventListener('click', displayCategories);
    categoryFilterBtnMobile.addEventListener('click', displayCategories);

    function displayCategories() {
        //remove display from category filter and dropdown
        ageFilterBtn.classList.remove("display");
        ageDropdown.classList.remove("display");
        categoryDropdown.classList.toggle("display");
        categoryDropdownMobile.classList.toggle("display");

        this.classList.toggle("display");
    }

    //Close filter dropdown on window click
    window.addEventListener('click', function (e) {
        if (!e.target.closest('.filter-item__dropdown, .filter-item')) {

            var openDropdowns = elem.querySelectorAll('.filter-item__dropdown.display');
            var clickedFilter = elem.querySelector('.filter-item.display');
            if (clickedFilter) {
                clickedFilter.classList.toggle('display');
            }
            if (openDropdowns) {

               openDropdowns.forEach(openDropdown => {
                    openDropdown.classList.toggle('display');
                });
            }
        }
    });

    //Filter click - Mobile
    filterMobileBtn.addEventListener('click', displayFilterMobileView);
    var mobileFilterOverlay = elem.querySelector('.method-overview-page__mobile-filter-overlay');

    function displayFilterMobileView() {
        mobileFilterOverlay.classList.add('open');
        mobileFilterOverlay.style.width = "100%";
    }

    //Filter close click - Mobile 
    filterMobileCloseBtn.addEventListener('click', closeFilterMobileView);

    function closeFilterMobileView() {
        mobileFilterOverlay.classList.remove('open');
        categoryDropdown.classList.remove("display");

        setTimeout(
            function () {
                mobileFilterOverlay.style.width = "0px";
        }, 500);
    }

    //Show result clock - Mobile
    showResultMobileBtn.addEventListener('click', closeFilterMobileView);
}